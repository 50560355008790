import React from 'react';
// import { questionsSectionData } from "../data";

const FAQSection = () => {
  const questionsSectionData = [
    {
      Question: 'Quem vai entregar meu presente?',
      Answer: 'Ele será entregue por uma rede de fornecedores sob a responsabilidade do Gifthy, que conta com logística de mercado moderna, rápida e segura, para que o seu ato de presentear tenha a eficiência que você merece e precisa.',
    },
    {
      Question: 'E se o meu presente não chegar a tempo?',
      Answer: 'O seu tempo é muito valioso para o Gifthy. Sua estrutura conta com profissionais qualificados, focados na experiência de cada usuário. Salvo a existência de problemas logísticos excepcionais, se você comprar com a antecedência necessária, o seu presente chegará a tempo.',
    },
    {
      Question: 'É o Gifthy quem vende os presentes?',
      Answer: 'O Gifthy possui uma vitrine virtual que contempla diversas opções de presentes dentro de um sistema de marketplace. Dentro dele, foram eleitos fornecedores externos que possuem a mais alta credibilidade no mercado para proporcionar segurança para quem compra o presente e a satisfação para quem o recebe.',
    },
    {
      Question: 'Como eu sei se o meu presente foi entregue?',
      Answer: 'No Gifthy você está sempre por dentro de cada passo. E para se sentir mais seguro, receberá uma notificação informando o status de entrega do seu presente assim que ele for recebido.',
    },
    {
      Question: 'Como faço o pagamento do presente',
      Answer: 'O pagamento no Gifthy é feito através de cartão de crédito ou boleto bancário, pelos sistemas usuais de pagamento de compras no meio virtual.',
    },
  ];
  return (
    <section className="faq" id="faq">
      <h1 className="title blue-font bold text-center">Principais Dúvidas</h1>
      <p className="description blue-font text-center">Veja abaixo as respostas para os principais questionamentos dos usuários Gifthy</p>
      <div className="wrapper">
        {questionsSectionData.map((item, index) => (
          <div key={index.toString()} className="item">
            <h1 className="question yellow-font bold text-center">{item.Question}</h1>
            <p className="answer blue-font text-center">{item.Answer}</p>
          </div>
        ))}
      </div>
      <div className="banner">
        <h1 className="title blue-font bold text-center">Você tem dúvidas? <span className="yellow-font">Entre em contato com a gente.</span></h1>
        <p className="description blue-font text-center">Compartilhe aqui a sua dúvida, crítica ou sugestão. O Gifthy quer estar sempre conectado a você, comprometido em apresentar as melhores soluções para o ato de presentear.</p>
      </div>            
    </section>
  );
};

export default FAQSection;