import React from "react";
import Layout from "../components/Layout/layout";
import { Helmet } from "react-helmet"

import FirstSection from './landing/sections/FirstSection';
import SecondSection from './landing/sections/SecondSection';
import ThirdSection from './landing/sections/ThirdSection';
import FourthSection from './landing/sections/FourthSection';
import FifthSection from './landing/sections/FifthSection';
// import SixthSection from './landing/sections/SixthSection';
import FAQSection from './landing/sections/FAQSection';
import ContactForm from './landing/sections/ContactSection';

const IndexPage = (props) => {  
    return (
        <Layout infoPage={{ page: "landing" }}>
            <Helmet>
                <script src="https://www.google.com/recaptcha/api.js?render=6LcB4LcZAAAAAPbot6BEB2Cv0Ygwf6Y5exJY8dDC"></script>
            </Helmet>
            <main className="landing">
                <FirstSection />
                <SecondSection />
                <ThirdSection />
                <FourthSection />
                <FifthSection />
                {/* <SixthSection /> */}
                <FAQSection />
                <div className="extra-banner">
                    <h1 className="title yellow-font bold text-center">Lojista, <span className="title blue-font bold text-center">venha participar do marketplace do Gifthy</span></h1>
                    <p className="description blue-font text-center">O Gifthy apresentará seu e-commerce a uma nova rede social. Seus produtos chegando a quem valoriza o ato de presentear.</p>
                </div>
                <ContactForm />
            </main>
        </Layout>
    )
}
export default IndexPage;   